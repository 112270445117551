import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet-async'
import { FiMail, FiFacebook, FiSend, FiCopy, FiLinkedin } from 'react-icons/fi'
import { FaXTwitter } from 'react-icons/fa6'
import { PiTelegramLogo } from 'react-icons/pi'
import { FaWhatsapp } from 'react-icons/fa'
import IntroducedClients from '../components/IntroducedClients'
import { referPartner } from '../features/partner/partnerSlice'
import Modal from '../components/Modal'

const PartnerReferBusiness = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const [copied, setCopied] = useState(false)
  const [referralCode, setReferralCode] = useState('')
  const [referralLink, setReferralLink] = useState('')
  const [codeCopied, setCodeCopied] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    referralCode: '',
    allowNameMention: false,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setBusinessInfo((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
      referralCode, // Ensure referralCode is always up to date
    }))
  }

  const handleSubmitBusinessInfo = (e) => {
    e.preventDefault()
    dispatch(referPartner(businessInfo))
      .unwrap()
      .then(() => {
        // Reset form and close modal
        setBusinessInfo({ name: '', address: '', phone: '', email: '', referralCode, allowNameMention: false })
        setIsModalOpen(false)
      })
      .catch((error) => {
        console.error('Failed to submit referral:', error)
      })
  }

  useEffect(() => {
    if (user && user.referralCode) {
      setReferralCode(user.referralCode)
      setReferralLink(`https://www.loyaltyclubplc.com/?ref=${user.referralCode}`)
      setBusinessInfo((prevState) => ({
        ...prevState,
        referralCode: user.referralCode,
      }))
    }
  }, [user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const referMessages = {
    email: `Hey there!
    I've been a loyal customer of yours for a while now, and I wanted to share something that I think could be great for your business: Loyalty Club PLC. It's an amazing loyalty program that I've seen other businesses use, and I'd love to see you offer it too!
    Here's why I think it would be great for your business:
    
    You could offer digital stamp cards, making it super easy for customers like me to earn rewards
    It could help bring in more repeat business
    You'd be able to send promotions directly to your customers' phones
    New customers could find you more easily through their interactive map
    It has a cool referral system that could help spread the word about your business
    There's even a digital currency called TEDS that's part of the system
    
    By using my referral code ${referralCode}, you'll get access to all these features. Plus, it would help me earn 250 TEDS (worth £25) - so it's a win-win!
    You can sign up here: ${referralLink}
    I'd be really excited to start earning rewards with you through this program. Let me know if you have any questions - I'd be happy to share more about why I think it would be great for your business!
    Best regards,
    ${user ? user.name : ''}
    A happy customer`,
    whatsapp: `Hey! 👋 I love coming to your business and I just found out about this cool loyalty program called Loyalty Club PLC. I think it could be amazing for your business!
    Here's why I think you should check it out:
    ✨ Benefits:
    
    Digital stamp cards (no more paper cards to lose!)
    Helps bring customers back more often
    You can send promos straight to customers' phones
    New customers can find you on their map
    Cool referral system to spread the word
    There's even a digital currency called TEDS
    
    If you join with my code ${referralCode}, I'll earn 250 TEDS (£25 value). It'd be awesome to start earning rewards with you!
    Sign up here: ${referralLink}
    Let me know if you want to chat more about it. I'd love to see you offer this! 😊`,
    twitter: `Hey @[YourBusinessHandle]! 🛍️
    As a regular customer, I'd love to see you use @LoyaltyClubPLC!
    
    Digital stamp rewards
    Easy customer messaging
    New customer discovery
    Cool referral system
    TEDS currency
    
    Use my code ${referralCode} - I'll earn 250 TEDS!
    Join: ${referralLink}
    #SupportLocal #CustomerLoyalty`,
    linkedin: `Hello [Business Name],
    As a loyal customer, I wanted to reach out about a fantastic tool I've discovered: Loyalty Club PLC. I believe it could really enhance our relationship and boost your business.
    Here's why I think Loyalty Club PLC would be great for [Business Name]:
    
    Digital Stamp Cards: No more paper cards for me to lose!
    Increased Customer Loyalty: It would definitely encourage me to visit more often.
    Direct Communication: You could send promotions straight to my phone.
    Expanded Reach: New customers could discover you through their interactive map.
    Referral Power: I could easily recommend you to friends through their system.
    TEDS Currency: There's an interesting digital currency aspect that connects businesses.
    
    By signing up with my referral code, you'd get access to all these features, and I'd earn 250 TEDS (£25 value). It's a win-win!
    My referral code: ${referralCode}
    Join here: ${referralLink}
    I'm excited about the possibility of earning rewards with you through this program. Feel free to reach out if you want to discuss how this could work for [Business Name]. I'd be happy to share why I'm enthusiastic about it!
    Looking forward to my next visit,
    [Your Name]
    A dedicated customer`,
    telegram: `Hey there! 👋 As one of your regular customers, I wanted to tell you about this cool program called Loyalty Club PLC. I really think it could be great for your business!
    Here's why I think you should check it out:
    ✨ Cool Features:
    
    Digital stamp cards (so convenient!)
    Helps bring customers back more often
    Send promos right to customers' phones
    New customers can find you easily
    Referral system to spread the word
    There's even a digital currency called TEDS
    
    If you sign up with my code ${referralCode}, I'll earn 250 TEDS (£25). It'd be awesome to start earning rewards when I shop with you!
    You can join here: ${referralLink}
    Let me know if you want to know more. I'd be so excited to see you offer this! 😊`,
  }

  const modalContent = (
    <form onSubmit={handleSubmitBusinessInfo} className='space-y-4'>
      <p className='text-sm mb-4'>
        By providing these details, you're taking a powerful step towards a successful referral. We'll handle the outreach with care and
        professionalism.
      </p>
      <input
        type='text'
        name='name'
        value={businessInfo.name}
        onChange={handleInputChange}
        placeholder='Business Name'
        className='w-full p-2 border rounded'
      />
      <input
        type='text'
        name='address'
        value={businessInfo.address}
        onChange={handleInputChange}
        placeholder='Business Address (optional)'
        className='w-full p-2 border rounded'
      />
      <input
        type='tel'
        name='phone'
        value={businessInfo.phone}
        onChange={handleInputChange}
        placeholder='Business Phone (optional)'
        className='w-full p-2 border rounded'
      />
      <input
        type='email'
        name='email'
        value={businessInfo.email}
        onChange={handleInputChange}
        placeholder='Business Email (optional)'
        className='w-full p-2 border rounded'
      />
      <div className='flex items-center'>
        <input
          type='checkbox'
          name='allowNameMention'
          id='allowNameMention'
          checked={businessInfo.allowNameMention}
          onChange={handleInputChange}
          className='mr-2 checkbox'
        />
        <label htmlFor='allowNameMention' className='text-sm'>
          Allow us to mention your name
        </label>
      </div>
      <button type='submit' className='btn btn-primary w-full'>
        Submit
      </button>
    </form>
  )

  // Set timeout for display of copied message
  useEffect(() => {
    let timeoutId
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false)
      }, 1400)
    }
    return () => clearTimeout(timeoutId)
  }, [copied])

  const handleEmailInvite = () => {
    const emailBody = encodeURIComponent(referMessages.email)
    const mailtoLink = `mailto:?subject=Join Loyalty Club PLC and transform your business!&body=${emailBody}`
    window.open(mailtoLink, '_self')
  }

  const handleFacebookInvite = () => {
    const encodedLink = encodeURIComponent(referralLink)
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`, '_blank')
  }

  const handleWhatsappInvite = () => {
    const whatsappMessage = encodeURIComponent(referMessages.whatsapp)
    window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`)
  }

  const handleTwitterInvite = () => {
    const twitterMessage = encodeURIComponent(referMessages.twitter)
    window.open(`https://twitter.com/intent/tweet?text=${twitterMessage}`)
  }

  const handleLinkedInInvite = () => {
    const linkedInMessage = encodeURIComponent(referMessages.linkedin)
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}&summary=${linkedInMessage}`)
  }

  const handleTelegramInvite = () => {
    const telegramMessage = encodeURIComponent(referMessages.telegram)
    window.open(`https://t.me/share/url?url=${referralLink}&text=${telegramMessage}`)
  }

  const handleReferralLinkClick = () => {
    navigator.clipboard.writeText(referralLink)
    setCopied(true)
  }

  const handleCopyCode = () => {
    navigator.clipboard.writeText(referralCode)
    setCodeCopied(true)
    setTimeout(() => setCodeCopied(false), 1400)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
    setLinkCopied(true)
    setTimeout(() => setLinkCopied(false), 1400)
  }

  return (
    <>
      <Helmet>
        <title>Refer a Business | Loyalty Club PLC</title>
      </Helmet>

      {/* Banner moved out of the centered container for full-width */}
      <div className='relative w-[calc(100vw-2rem)] left-1/2 -translate-x-1/2 bg-gradient-to-r from-orange-400 to-yellow-400 rounded-2xl p-8 mb-8 overflow-hidden shadow-xl'>
        <div className='flex items-center justify-between'>
          <div className='text-white space-y-4'>
            <h2 className='text-4xl font-bold leading-tight'>
              Refer a Business
              <br />
              <span className='text-6xl block mt-2'>
                Earn <span className='text-7xl'>250 TEDS</span>
                <span className='text-3xl block mt-2'>(£25 value) When They Join</span>
              </span>
            </h2>
            <div className='space-y-2'>
              <div className='flex items-center space-x-2 text-sm'>
                <span>Terms apply | 1 TEDS = £0.10</span>
              </div>
            </div>
          </div>
          <div className='relative w-48 h-48'>
            <img src='/images/TEDSLogo-sm.png' alt='TEDS Currency' className='absolute w-full h-full object-contain animate-float' />
            <div className='absolute inset-0 bg-gradient-to-r from-transparent to-white/20 animate-shine' />
          </div>
        </div>
        {/* Decorative element */}
        <div className='absolute top-0 right-0 w-32 h-32 bg-white/10 rounded-full -mt-16 -mr-16' />
      </div>

      {/* Main content */}
      <div className='flex flex-col items-center space-y-4 w-full'>
        <p className='m-6 pb-7 text-xs md:text-base'>
          Spread the word about Loyalty Club PLC and earn rewards! For each successful referral, you'll receive 250 TEDS (worth £25). Help fellow
          businesses boost their customer loyalty while growing your TEDS balance. Start referring now!
        </p>

        <div className='grid grid-cols-4 gap-4 md:flex md:space-x-7'>
          <IconContext.Provider value={{ className: 'text-blue-500 cursor-pointer hover:text-blue-700' }}>
            <div onClick={handleEmailInvite}>
              <FiMail size={36} />
            </div>
            <div onClick={handleFacebookInvite}>
              <FiFacebook size={36} />
            </div>
            <div onClick={handleTelegramInvite}>
              <PiTelegramLogo size={36} />
            </div>
            <div onClick={handleTwitterInvite}>
              <FaXTwitter size={36} />
            </div>
            <div onClick={handleLinkedInInvite}>
              <FiLinkedin size={36} />
            </div>
            <div onClick={handleWhatsappInvite}>
              <FaWhatsapp size={36} />
            </div>
            <div onClick={handleCopyLink}>
              <FiCopy size={36} />
            </div>
          </IconContext.Provider>
        </div>

        {/* Referral Info Section */}
        <div className='bg-orange-200 p-4 rounded-lg shadow-md w-full max-w-md pb-20' style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <h2 className='text-xl font-semibold mb-2'>Your Referral Information</h2>
          <div className='space-y-4'>
            <div className='bg-white rounded p-2'>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Referral Code</label>
              <div className='flex items-center justify-between'>
                <span className='text-lg font-mono font-bold'>{referralCode}</span>
                <button onClick={handleCopyCode} className='btn btn-primary'>
                  {codeCopied ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </div>
            <div className='bg-white rounded p-2'>
              <label className='block text-sm font-medium text-gray-700 mb-1'>Referral Link</label>
              <div className='flex items-center justify-between'>
                <span className='text-sm font-mono truncate mr-2'>{referralLink}</span>
                <button onClick={handleCopyLink} className='btn btn-primary'>
                  {linkCopied ? 'Copied!' : 'Copy'}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full max-w-md bg-gray-200 p-6 rounded-lg shadow-md'>
          <h2 className='text-xl font-semibold mb-4'>Boost Your Referral Success</h2>
          <p className='text-sm mb-4'>
            While sharing your referral link is a great start, there's an even more effective way to increase your chances of earning TEDS bonuses. By
            providing business details, you can supercharge your referral efforts. Here's why:
          </p>
          <ul className='list-disc list-inside text-sm mb-4 space-y-2 font-bold'>
            <li>We'll reach out directly to the business, significantly increasing the likelihood of sign-up</li>
            <li>A personalized approach often leads to higher success rates</li>
            <li>You'll be taking a proactive step towards earning your 250 TEDS bonus</li>
            <li>It's an easy way to multiply your referral success with minimal effort</li>
          </ul>
          <p className='text-sm mb-6 font-medium'>Ready to maximize your referral potential? Let us help you succeed!</p>
          <button onClick={() => setIsModalOpen(true)} className='btn btn-primary w-full'>
            Provide Business Details
          </button>
        </div>

        <div className='w-full pb-20'>
          <IntroducedClients />
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title='Business Information'>
        {modalContent}
      </Modal>

      <style>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        @keyframes shine {
          to { transform: translateX(200%); }
        }
        .animate-float {
          animation: float 4s ease-in-out infinite;
        }
        .animate-shine {
          animation: shine 3s ease-in-out infinite;
          transform: translateX(-100%);
        }
      `}</style>
    </>
  )
}

export default PartnerReferBusiness
