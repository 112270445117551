import api from '../../api'
import axios from 'axios'
const { REACT_APP_SERVER_URL } = process.env

const API_URL = `${REACT_APP_SERVER_URL}/api/customers`

// Auth check
const authCheck = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/auth-check`, config)
    return response.data
  } catch (error) {
    throw error
  }
}

// Register a new user
const register = async (userData) => {
  console.log('userData', userData)
  try {
    const response = await axios.post(API_URL, userData)

    if (response.data.error) {
      throw new Error(response.data.error)
    }

    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
  } catch (error) {
    // Here, we're catching any error that could arise from the api.post call
    throw error
  }
}

const login = async (userData) => {
  try {
    const response = await axios.post(API_URL + '/login', userData)
    localStorage.setItem('user', JSON.stringify(response.data))
    return response.data
  } catch (error) {
    console.log('error', error)
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message)
    } else {
      throw new Error(error.message || 'Unknown error')
    }
  }
}

// Accept privacy policy
const setPrivacyPolicyAccepted = async (token) => {
  try {
    console.log('1')
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.put(API_URL + '/accept-privacy-policy', null, config)
    console.log('2')
    // Assuming the server returns the updated user data
    if (response.data.customer) {
      const updatedUser = {
        ...JSON.parse(localStorage.getItem('user')),
        ...response.data.customer,
      }

      localStorage.setItem('user', JSON.stringify(updatedUser))
    }

    return response.data
  } catch (error) {
    console.log('error', error)
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message)
    } else {
      throw new Error(error.message || 'Unknown error')
    }
  }
}

// Initiate password reset
const resetPassword = async (emailAddress) => {
  console.log('🚀 ~ file: authService.js:129 ~ resetPassword ~ emailAddress:', emailAddress)
  const response = await axios.post(`${API_URL}/forgot-password`, { email: emailAddress })
  return response.data
}

// *get customer details
const getCustomerDetails = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.get(`${API_URL}/get-customer-details`, config)

    return response.data
  } catch (error) {
    throw error
  }
}

// update a customers user details
const updateCustomerDetails = async (customerData, token) => {
  try {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json', // Add this to tell the server you're sending JSON
    })

    const request = new Request(`${API_URL}/update-customer`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(customerData), // Add this to send the userData object as a JSON string
    })

    const response = await fetch(request)
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.message || `Server returned a ${response.status} status code`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error(`An error occurred while pausing the server: ${error}`)
    throw error
  }
}

// *get customers ID via shortcode
const getClientID = async (shortCode, token) => {
  console.log('shortCode', shortCode)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await api.get(`${API_URL}/get-client-id/${shortCode}`, config)

  return response.data
}

// logout a new user
const logout = () => {
  localStorage.removeItem('user')
}

// Delete customer account (with password verification)
const deleteAccount = async (password, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await api.delete(`${API_URL}/delete-account`, {
      headers: config.headers,
      data: { password }
    })
    
    // On successful deletion, remove user from local storage
    if (response.data.success) {
      localStorage.removeItem('user')
    }
    
    return response.data
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message)
    } else {
      throw new Error(error.message || 'An error occurred while deleting your account')
    }
  }
}

const authService = {
  register,
  login,
  logout,
  resetPassword,
  setPrivacyPolicyAccepted,
  updateCustomerDetails,
  getCustomerDetails,
  getClientID,
  authCheck,
  deleteAccount,
}

export default authService
