import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AiOutlineQrcode } from 'react-icons/ai'
import { BiScan } from 'react-icons/bi'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { VscSettings } from 'react-icons/vsc'
import { GiHamburgerMenu } from 'react-icons/gi'

function BottomNav() {
  const { user } = useSelector((state) => state.auth)

  if (!user.acceptedPrivacyPolicy) {
    return ''
  }

  return (
    <div className='fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t  border-gray-200 dark:bg-gray-700 dark:border-gray-600 custom-zindex-less'>
      <div className='grid h-full max-w-lg grid-cols-3 mx-auto font-medium '>
        <NavLink to='/' className='inline-flex flex-col items-center justify-center px-5 md:hover:bg-gray-50 dark:md:hover:bg-gray-800 group'>
          {({ isActive }) => (
            <>
              <GiHamburgerMenu
                className={`text-2xl ${
                  isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 md:group-hover:text-primary dark:md:hover:text-primary'
                }`}
              />
              <span
                className={`text-sm ${
                  isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 md:group-hover:text-primary dark:md:hover:text-primary'
                }`}>
                Home
              </span>
            </>
          )}
        </NavLink>

        <NavLink to='/reader' className='inline-flex flex-col items-center justify-center px-5 md:hover:bg-gray-50 dark:md:hover:bg-gray-800 group'>
          {({ isActive }) => (
            <>
              <BiScan
                className={`text-2xl ${
                  isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 md:group-hover:text-primary dark:md:hover:text-primary'
                }`}
              />
              <span
                className={`text-sm ${
                  isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 md:group-hover:text-primary dark:md:hover:text-primary'
                }`}>
                Join Campaign
              </span>
            </>
          )}
        </NavLink>

        <NavLink to='/myqr' className='inline-flex flex-col items-center justify-center px-5 md:hover:bg-gray-50 dark:md:hover:bg-gray-800 group'>
          {({ isActive }) => (
            <>
              <AiOutlineQrcode
                className={`text-2xl ${
                  isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 md:group-hover:text-primary dark:md:hover:text-primary'
                }`}
              />
              <span
                className={`text-sm ${
                  isActive ? 'text-orange-500' : 'text-gray-500 dark:text-gray-400 md:group-hover:text-primary dark:md:hover:text-primary'
                }`}>
                My QR
              </span>
            </>
          )}
        </NavLink>
      </div>
    </div>
  )
}

export default BottomNav
