import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineQrcode } from 'react-icons/ai'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BiScan, BiMessageAdd } from 'react-icons/bi'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { MdOutlineMessage } from 'react-icons/md'
import { FaHandshake, FaMapMarkedAlt } from 'react-icons/fa'
import { authCheck } from '../features/auth/authSlice'
import { BsWallet2 } from 'react-icons/bs'
import { FaExchangeAlt } from 'react-icons/fa'
import { LuHeartHandshake } from 'react-icons/lu'

function Home() {
  const items = [
    { link: '/my-campaigns', title: 'Campaigns', Icon: HiOutlineSpeakerphone },
    { link: '/myqr', title: 'My QR Code', Icon: AiOutlineQrcode },
    { link: '/partners-map', title: 'Partners Map', Icon: FaMapMarkedAlt },
    { link: '/reader', title: 'Join Campaign', Icon: BiScan },
    { link: '/messages', title: 'Message Center', Icon: MdOutlineMessage },
    { link: '/teds/transactions', title: 'Transactions', Icon: FaExchangeAlt },
    { link: '/refer-friends', title: 'Refer a Friend', Icon: FaHandshake },
    { link: '/refer-a-partner', title: 'Refer A Business', Icon: LuHeartHandshake },
    { link: '/teds/wallet', title: 'Wallet', Icon: BsWallet2 },
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!user.acceptedPrivacyPolicy) {
      navigate('/privacy-policy')
    }
  }, [user, navigate])

  useEffect(() => {
    dispatch(authCheck())
  }, [dispatch])

  return (
    <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-5 mb-10'>
      <Helmet>
        <title>Home | Loyalty Club PLC</title>
      </Helmet>
      {items.map((item) => (
        <Link to={item.link} key={item.link} className='group transform transition-transform duration-500 md:hover:scale-105'>
          <div className='card w-full h-36 bg-base-100 shadow-xl md:hover:shadow-2xl md:hover:bg-gray-100'>
            <div className='card-body flex flex-col justify-start items-center'>
              <div>
                <item.Icon size={36} className='mx-auto md:group-hover:text-primary' />
              </div>

              <h2 className='card-title text-sm md:group-hover:text-primary'>{item.title}</h2>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default Home
