import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet-async'
import { FiMail, FiFacebook, FiSend, FiCopy, FiLinkedin, FiMessageSquare } from 'react-icons/fi'
import { FaXTwitter } from 'react-icons/fa6'
import { PiTelegramLogo } from 'react-icons/pi'
import { FaWhatsapp } from 'react-icons/fa'
import { fetchCustomer, reset } from '../features/campaign/campaignSlice'
import { getReferFriendMessages } from '../features/message/messageSlice'
import { customToast } from '../components/CustomToast'
import Spinner from '../components/Spinner'

const ReferFriend = () => {
  const { user } = useSelector((state) => state.auth)
  const { isSuccess, isError, isLoading, message, userDetails } = useSelector((state) => state.campaign)
  const { referFriendMessages } = useSelector((state) => state.message)

  const [copied, setCopied] = useState(false)
  const [referralCode, setReferralCode] = useState(user.referralCode)
  const [selectedBusinessName, setSelectedBusinessName] = useState(userDetails?.clients?.length === 1 ? userDetails.clients[0].businessName : '')
  const [selectedOption, setSelectedOption] = useState(false)

  const referralLink = `https://members.loyaltyclubplc.com/register?referralCode=${referralCode}`

  const dispatch = useDispatch()

  // Reset state when there is a success or error
  useEffect(() => {
    if (isSuccess) {
      dispatch(reset())
    }

    if (isError) {
      customToast.error(message.message)
      dispatch(reset())
    }
  }, [dispatch, isSuccess, isError, message])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // Fetch refer-a-friend messages when selectedBusinessName changes
  useEffect(() => {
    if (selectedBusinessName) {
      const selectedClient = userDetails.clients.find((client) => client.businessName === selectedBusinessName)
      if (selectedClient) {
        dispatch(getReferFriendMessages(selectedClient.clientID))
      }
    }
  }, [dispatch, selectedBusinessName, userDetails.clients])

  useEffect(() => {
    dispatch(fetchCustomer())
  }, [dispatch])

  // set timeout for display of copied message
  useEffect(() => {
    let timeoutId
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false)
      }, 1400)
    }
    return () => clearTimeout(timeoutId)
  }, [copied])

  // Set default selection if only one merchant exists
  useEffect(() => {
    if (userDetails?.clients?.length === 1) {
      setSelectedBusinessName(userDetails.clients[0].businessName)
      setReferralCode(userDetails.clients[0].referralCode)
      setSelectedOption(true)
    }
  }, [userDetails?.clients])

  const handleCopyLink = () => {
    if (selectedOption) {
      navigator.clipboard.writeText(referralLink)
      setCopied(true)
    }
  }

  const handleEmailInvite = () => {
    if (selectedOption) {
      const emailBody = encodeURIComponent(
        referFriendMessages.Email.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      const mailtoLink = `mailto:?subject=Join me at ${selectedBusinessName} to earn rewards!&body=${emailBody}`
      window.open(mailtoLink, '_self')
    }
  }

  const handleFacebookInvite = () => {
    if (selectedOption) {
      const encodedLink = encodeURIComponent(referralLink)
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`, '_blank')
    }
  }

  const handleWhatsappInvite = () => {
    if (selectedOption) {
      const whatsappMessage = encodeURIComponent(
        referFriendMessages.WhatsApp.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://api.whatsapp.com/send?text=${whatsappMessage}`)
    }
  }

  const handleTwitterInvite = () => {
    if (selectedOption) {
      const twitterMessage = encodeURIComponent(
        referFriendMessages.Twitter.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://twitter.com/intent/tweet?text=${twitterMessage}`)
    }
  }

  const handleLinkedInInvite = () => {
    if (selectedOption) {
      const linkedInMessage = encodeURIComponent(
        referFriendMessages.LinkedIn.replace('{selectedBusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${linkedInMessage}`)
    }
  }

  const handleTelegramInvite = () => {
    if (selectedOption) {
      const telegramMessage = encodeURIComponent(
        referFriendMessages.Telegram.replace('{BusinessName}', selectedBusinessName)
          .replace('{referralCode}', referralCode)
          .replace('{referralLink}', referralLink)
      )
      window.open(`https://t.me/share/url?url=${telegramMessage}`)
    }
  }

  const handleReferralLinkClick = () => {
    navigator.clipboard.writeText(referralLink)
    setCopied(true)
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='mb-20'>
      <Helmet>
        <title>Refer a Friend | Loyalty Club PLC</title>
      </Helmet>

      {/* Banner moved out of the centered flex container for full-width */}
      <div className='relative w-[calc(100vw-2rem)] left-1/2 -translate-x-1/2 bg-gradient-to-r from-orange-400 to-yellow-400 py-8 px-4 md:py-4 -mt-4 mb-8 overflow-hidden shadow-2xl rounded-3xl min-h-[200px] md:min-h-[200px]'>
        {/* Scanning Beam Effect */}
        <div className='absolute inset-0 bg-gradient-to-b from-orange-200/20 via-yellow-300/30 to-transparent opacity-25 animate-scan-beam'></div>
        {/* Geometric Elements */}
        <div className='absolute inset-0 opacity-40 mix-blend-soft-light'>
          <div className='absolute inset-0 bg-[length:400%_400%] bg-gradient-to-r from-blue-400/20 via-transparent to-purple-400/20 animate-gradient-flow'></div>
          <div className='absolute inset-0 bg-noise opacity-10'></div>
        </div>
        {/* Content Container */}
        <div className='container max-w-7xl mx-auto px-4'>
          <div className='relative z-10 h-full flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0'>
            {/* Left Logo Section - Updated */}
            <div className='relative w-28 h-28 md:w-48 md:h-48 flex-shrink-0 flex items-center justify-center mb-12 mt-8'>
              <div className='flex flex-col items-center'>
                <img src='/images/loyalty-stamp.svg' alt='Stamp Icon' className='animate-float h-28 w-28 md:h-32 md:w-32 rounded-lg object-cover' />
                <img
                  src='/images/stamp-icon.svg'
                  alt='Loyalty Stamp'
                  className='mt-2 h-20 w-20 md:h-24 md:w-24 transform'
                  style={{ transform: 'rotate(310deg)' }}
                />
                {/* Add shine effect */}
                <div className='absolute inset-0 bg-gradient-to-r from-transparent to-white/20 animate-shine' />
              </div>
            </div>
            {/* Center Text Section */}
            <div className='text-white space-y-2 text-center max-w-2xl md:max-w-3xl md:mx-12'>
              <h2 className='text-2xl md:text-4xl font-bold leading-tight'>
                <span className='inline-block bg-clip-text text-transparent bg-gradient-to-r from-white to-amber-100'>Refer Friends</span>
                <br />
                <span className='text-xl md:text-3xl block mt-2'>
                  Earn <span className='text-7xl'>Stamps</span>
                  <span className='text-3xl block mt-2'>
                    When Friends Visit
                    <span className='block text-lg mt-1 opacity-80'>Rewards vary by merchant</span>
                  </span>
                </span>
              </h2>
            </div>
          </div>
        </div>
        {/* Decorative element */}
        <div className='absolute top-0 right-0 w-32 h-32 bg-white/10 rounded-full -mt-16 -mr-16' />
        {/* Animated border elements */}
        <div className='absolute inset-0 border-4 border-white/5 rounded-2xl pointer-events-none'></div>
        <div className='absolute inset-0 border-[12px] border-transparent [mask:linear-gradient(45deg,transparent_10%,white_50%,transparent_90%)] animate-border-shine'></div>
      </div>

      {/* Main content */}
      <div className='flex flex-col items-center space-y-4'>
        <select
          className='select select-bordered w-full max-w-xs mt-1'
          value={selectedBusinessName}
          onChange={(e) => {
            const selectedClient = userDetails.clients.find((client) => client.businessName === e.target.value)
            setReferralCode(selectedClient.referralCode)
            setSelectedOption(true)
            setSelectedBusinessName(e.target.value)
          }}>
          <option value=''>Please select a merchant to refer</option>
          {userDetails?.clients?.map((client) => (
            <option key={client.clientID} value={client.businessName}>
              {client.businessName}
            </option>
          ))}
        </select>

        <div className='grid grid-cols-4 gap-4 md:flex md:space-x-7'>
          <IconContext.Provider
            value={{
              className: `text-blue-500 cursor-pointer hover:text-blue-700 ${selectedOption ? '' : 'opacity-50 pointer-events-none'}`,
            }}>
            <div onClick={handleEmailInvite}>
              <FiMail size={36} />
            </div>
            <div onClick={handleFacebookInvite}>
              <FiFacebook size={36} />
            </div>
            <div onClick={handleTelegramInvite}>
              <PiTelegramLogo size={36} />
            </div>
            <div onClick={handleTwitterInvite}>
              <FaXTwitter size={36} />
            </div>
            <div onClick={handleLinkedInInvite}>
              <FiLinkedin size={36} />
            </div>
            <div onClick={handleWhatsappInvite}>
              <FaWhatsapp size={36} />
            </div>
            <div onClick={handleCopyLink}>
              <FiCopy size={36} />
            </div>
          </IconContext.Provider>
        </div>

        {selectedOption && (
          <>
            <div className='text-sm text-gray-500'>Share your referral link</div>
            <div className='bg-gray-200 rounded-lg p-2 text-sm text-gray-500 relative cursor-pointer' onClick={handleReferralLinkClick}>
              {referralLink}
              {copied && (
                <div className='bg-green-500 text-white px-2 py-1 rounded absolute bottom-12 left-1/2 transform -translate-x-1/2'>
                  Link copied to clipboard
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {/* Add the animation styles */}
      <style>{`
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-20px); }
        }
        @keyframes shine {
          to { transform: translateX(200%); }
        }
        .animate-float {
          animation: float 4s ease-in-out infinite;
        }
        .animate-shine {
          animation: shine 3s ease-in-out infinite;
          transform: translateX(-100%);
        }
      `}</style>
    </div>
  )
}

export default ReferFriend
