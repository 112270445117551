import React from 'react'

const Modal = ({ children, onClose, title, isOpen }) => {
  return (
    <div className={`modal modal-bottom sm:modal-middle ${isOpen ? 'modal-open' : ''} custom-zindex`}>
      <div className='modal-box relative w-11/12 max-w-[90vw] lg:max-w-[1200px] p-6'>
        <button onClick={onClose} className='btn btn-sm btn-circle absolute right-2 top-2'>
          ✕
        </button>
        {title && <h3 className='font-bold text-lg mb-4'>{title}</h3>}
        <div className='overflow-y-auto max-h-[80vh]'>{children}</div>
        <div className='modal-action'>
          <button className='btn' onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default Modal
