import { useState, useEffect } from 'react'
import BackButton from '../components/BackButton'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PrivacyDashboard from '../components/PrivacyDashboard'
import { updateCustomerDetails, getCustomerDetails, reset, deleteAccount, logout } from '../features/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import { customToast } from '../components/CustomToast'
import { useNavigate } from 'react-router-dom'

const Settings = () => {
  const { user, customerDetails, isLoading, isSuccess, isError, message } = useSelector((state) => state.auth)

  const [emailError, setEmailError] = useState('')
  const [formErrors, setFormErrors] = useState({})

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [isEditing, setIsEditing] = useState(false)
  
  // Account deletion states
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showFinalConfirmation, setShowFinalConfirmation] = useState(false)
  const [showDeletionConfirmed, setShowDeletionConfirmed] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [authenticationSuccess, setAuthenticationSuccess] = useState(false)
  const [deletePassword, setDeletePassword] = useState('')
  const [deletePasswordError, setDeletePasswordError] = useState('')
  
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    email: user?.email || '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  useEffect(() => {
    if (isSuccess) {
      customToast.success(customerDetails?.message)
    }

    if (isError) {
      customToast.error(message)
    }
    dispatch(reset())
  }, [customerDetails, isError, message, isSuccess, dispatch])

  const onSubmit = (e) => {
    e.preventDefault()
    setIsSubmitted(true)

    const userData = {
      email: formData.email,
    }

    // Add password data only if provided.
    if (formData.currentPassword && formData.newPassword && formData.confirmNewPassword) {
      userData.currentPassword = formData.currentPassword
      userData.newPassword = formData.newPassword
      userData.confirmNewPassword = formData.confirmNewPassword
    }

    dispatch(updateCustomerDetails(userData)).then((res) => {
      if (res.type === updateCustomerDetails.fulfilled.type) {
        setIsEditing(false)

        // Reset the password fields
        setFormData((prevState) => ({
          ...prevState,
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }))
      }
    })
  }

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const onChange = (e) => {
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setEmailError('Invalid email')
      } else {
        setEmailError('')
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onEditButtonClick = () => {
    setIsEditing(true)
  }

  const onCancelButtonClick = () => {
    setIsEditing(false)
    setFormData((prevState) => ({
      ...prevState,
      email: user.email,
    }))
  }

  // Redirect to login if user is not available
  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
  }, [user, navigate])

  useEffect(() => {
    if (user) {
      dispatch(getCustomerDetails())
    }
  }, [dispatch, user])

  useEffect(() => {
    if (customerDetails) {
      setFormData((prevState) => ({
        ...prevState,
        email: customerDetails.email || prevState.email,
      }))
    }
  }, [customerDetails, isSuccess])
  
  // Handle account deletion success and redirect
  useEffect(() => {
    if (authenticationSuccess && showDeletionConfirmed) {
      // Start a timer to logout and redirect after showing the confirmation message
      const timer = setTimeout(() => {
        dispatch(logout())
        navigate('/login')
      }, 5000) // 5 seconds delay to let user read the message
      
      return () => clearTimeout(timer)
    }
  }, [authenticationSuccess, showDeletionConfirmed, dispatch, navigate])

  return (
    <>
      <Helmet>
        <title>Settings | Loyalty Club PLC</title>
      </Helmet>

      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50 shadow-lg'>
        <h1 className='font-bold text-2xl md:text-left'>Profile</h1>
        <div className='divider'></div>

        <form onSubmit={onSubmit}>
          {/* Update email */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CHANGE EMAIL</label>
            <div className='flex w-full  '>
              {isEditing ? (
                <input
                  type='text'
                  className='w-full p-3  border border-gray-300 rounded-md text-xs md:text-base'
                  placeholder='Enter Email Address'
                  id='email'
                  name='email'
                  value={formData.email}
                  onChange={onChange}
                />
              ) : (
                <span className='mr-3'>{formData.email || ''}</span>
              )}
              {!isEditing && (
                <button type='button' className='btn btn-sm mr-3' onClick={onEditButtonClick}>
                  Edit
                </button>
              )}
              {isEditing && (
                <button type='button' className='btn mr-4 ml-2' onClick={onCancelButtonClick}>
                  Cancel
                </button>
              )}
            </div>
          </div>

          <div className='mt-8 mb-8'>
            {' '}
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>UPDATE PASSWORD</label>
            <p className='mb-3 text-xs '>You can update your password here. If you don't want to change your password, leave these fields empty.</p>
          </div>

          {/* Current Password */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2 '>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CURRENT PASSWORD</label>
            <input
              type='password'
              className='w-full p-3 border border-gray-300 rounded-md text-xs md:text-base'
              id='currentPassword'
              name='currentPassword'
              value={formData.currentPassword}
              onChange={onChange}
              placeholder='Enter Current Password'
            />
          </div>

          {/* New Password */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>NEW PASSWORD</label>
            <input
              type='password'
              className='w-full p-3 border border-gray-300 rounded-md text-xs md:text-base'
              id='newPassword'
              name='newPassword'
              value={formData.newPassword}
              onChange={onChange}
              placeholder='Enter New Password'
            />
          </div>

          {/* Confirm New Password */}
          <div className='flex flex-col sm:flex-row items-start sm:items-center mb-2'>
            <label className='text-xs  w-full sm:w-1/5 text-left mb-1 sm:mb-0 font-titillium-web tracking-wider'>CONFIRM NEW PASSWORD</label>
            <input
              type='password'
              className='w-full p-3 border border-gray-300 rounded-md md:text-base text-xs'
              id='confirmNewPassword'
              name='confirmNewPassword'
              value={formData.confirmNewPassword}
              onChange={onChange}
              placeholder='Confirm New Password'
            />
          </div>
          {isEditing && emailError && <p className='text-red-500 text-sm'>{emailError}</p>}
          <button className='btn btn-primary btn-sm flex mt-8' disabled={isLoading || emailError}>
            {isLoading ? (
              <>
                <img className='' src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Saving...</span>
              </>
            ) : (
              <span>Save Changes</span>
            )}
          </button>

          {isSubmitted && Object.keys(formErrors).length > 0 && (
            <span className='text-red-500 text-sm ml-2 flex mt-2'>Please fill in all required fields</span>
          )}
        </form>
      </div>
      
      <PrivacyDashboard />
      
      {/* Account Deletion Section */}
      <div className='flex flex-col w-full justify-between items-left border p-4 rounded-md bg-gray-50 shadow-lg mt-8 text-left'>
        <h1 className='font-bold text-2xl md:text-left'>Account</h1>
        <div className='divider'></div>
        
        {!showDeleteConfirmation && !showFinalConfirmation && !showDeletionConfirmed && (
          <div className='mb-4'>
            <p className='text-sm mb-4 text-left'>
              If you no longer wish to use your Loyalty Club PLC account, you can request to delete it here. 
              Please note that some data may be retained for legal compliance.
            </p>
            <button 
              type='button' 
              className='btn btn-error btn-sm' 
              onClick={() => setShowDeleteConfirmation(true)}
            >
              Delete My Account
            </button>
          </div>
        )}
        
        {/* Step 2: Confirmation Screen */}
        {showDeleteConfirmation && !showFinalConfirmation && !showDeletionConfirmed && (
          <div className='bg-gray-100 p-4 rounded-md text-left'>
            <h2 className='font-bold text-lg mb-3'>We're sorry to see you go!</h2>
            <p className='text-sm mb-4 text-left'>Before proceeding, please review what happens when you delete your account:</p>
            
            <div className='mb-4'>
              <h3 className='font-bold text-md'>What Will Be Deleted?</h3>
              <ul className='list-disc ml-5 text-sm'>
                <li>Your personal data, profile, and account information</li>
                <li>Loyalty stamps, TEDS Tokens, and campaign history</li>
                <li>Active rewards and referrals (this cannot be undone)</li>
              </ul>
            </div>
            
            <div className='mb-4'>
              <h3 className='font-bold text-md'>What May Be Retained? (Due to legal regulations)</h3>
              <ul className='list-disc ml-5 text-sm'>
                <li>Certain transaction data required for Anti-Money Laundering (AML) compliance</li>
                <li>Fraud prevention logs, as required by law</li>
              </ul>
            </div>
            
            <p className='font-bold text-sm mb-4 text-left'>Are you sure you want to continue?</p>
            
            <div className='flex mt-4'>
              <button 
                type='button' 
                className='btn btn-neutral btn-sm uppercase mr-2' 
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </button>
              <button 
                type='button' 
                className='btn btn-error btn-sm uppercase' 
                onClick={() => {
                  setShowDeleteConfirmation(false)
                  setShowFinalConfirmation(true)
                }}
              >
                Continue to Delete
              </button>
            </div>
          </div>
        )}
        
        {/* Step 3: Final Confirmation & Authentication */}
        {showFinalConfirmation && !showDeletionConfirmed && (
          <div className='bg-gray-100 p-4 rounded-md text-left'>
            <h2 className='font-bold text-lg mb-3'>Please Authenticate</h2>
            <p className='text-sm mb-4 text-left'>Please confirm your request by authenticating. This action is permanent and cannot be undone.</p>
            
            {!isAuthenticating && !authenticationSuccess && (
              <div className='mt-4'>
                <div className='mb-4'>
                  <label className='text-sm font-bold mb-2 block text-left'>Enter your password to confirm:</label>
                  <input
                    type='password'
                    className='w-full p-3 border border-gray-300 rounded-md'
                    placeholder='Enter your password'
                    value={deletePassword}
                    onChange={(e) => {
                      setDeletePassword(e.target.value)
                      setDeletePasswordError('')
                    }}
                  />
                  {deletePasswordError && (
                    <p className='text-red-500 text-sm mt-1'>{deletePasswordError}</p>
                  )}
                </div>
                
                <div className='flex'>
                  <button 
                    type='button' 
                    className='btn btn-error btn-sm uppercase mr-2' 
                    disabled={!deletePassword}
                    onClick={() => {
                      if (!deletePassword) {
                        setDeletePasswordError('Password is required to delete your account')
                        return
                      }
                      
                      setIsAuthenticating(true)
                      
                      // Use the real authentication with password validation
                      dispatch(deleteAccount(deletePassword))
                        .unwrap()
                        .then(() => {
                          setIsAuthenticating(false)
                          setAuthenticationSuccess(true)
                          // After successful authentication, show deletion confirmation
                          setTimeout(() => {
                            setShowFinalConfirmation(false)
                            setShowDeletionConfirmed(true)
                            
                            // Show confirmation message for a few seconds before logout
                            // The logout and redirect will happen via the useEffect hook
                          }, 1000)
                        })
                        .catch((error) => {
                          setIsAuthenticating(false)
                          setDeletePasswordError(error || 'Invalid password. Please try again.')
                        })
                    }}
                  >
                    Delete My Account
                  </button>
                  <button 
                    type='button' 
                    className='btn btn-neutral btn-sm uppercase' 
                    onClick={() => {
                      setShowFinalConfirmation(false)
                      setIsAuthenticating(false)
                      setAuthenticationSuccess(false)
                      setDeletePassword('')
                      setDeletePasswordError('')
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            
            {isAuthenticating && (
              <div className='flex items-center mt-4'>
                <img className='mr-2' src='/images/loadingSpinner.svg' alt='Loading...' />
                <span>Authenticating...</span>
              </div>
            )}
            
            {authenticationSuccess && (
              <div className='text-success mt-4'>
                <span>Authentication successful!</span>
              </div>
            )}
          </div>
        )}
        
        {/* Step 4: Account Deletion Request Confirmation */}
        {showDeletionConfirmed && (
          <div className='bg-gray-100 p-4 rounded-md text-left'>
            <h2 className='font-bold text-lg mb-3'>Your account deletion request has been received!</h2>
            
            <div className='mb-4'>
              <h3 className='font-bold text-md'>Immediate Changes:</h3>
              <ul className='list-disc ml-5 text-sm'>
                <li>You will be logged out.</li>
                <li>Your account will be deactivated immediately.</li>
              </ul>
            </div>
            
            <div className='mb-4'>
              <h3 className='font-bold text-md'>Deletion Timeline:</h3>
              <p className='text-sm text-left'>
                Your account and personal data will be permanently deleted within 30 days, except for legally required records. 
                If you change your mind, you may contact support before this period ends.
              </p>
            </div>
            
            <div className='mb-4'>
              <h3 className='font-bold text-md'>Need Help?</h3>
              <p className='text-sm text-left'>For any issues, contact our support team.</p>
            </div>
            
            <button 
              type='button' 
              className='btn btn-primary btn-sm' 
              onClick={() => {
                // Reset all states
                setShowDeleteConfirmation(false)
                setShowFinalConfirmation(false)
                setShowDeletionConfirmed(false)
                setIsAuthenticating(false)
                setAuthenticationSuccess(false)
                setDeletePassword('')
                setDeletePasswordError('')
                
                // Logout and redirect to login
                dispatch(logout())
                navigate('/login')
              }}
            >
              OK
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default Settings
