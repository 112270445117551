import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localstorage

// Get user from localStorage
const userFromStorage = localStorage.getItem('user')
const user = userFromStorage ? JSON.parse(userFromStorage) : null

// Ensure the user object from localStorage has a wallet property
if (user && !user.wallet) {
  user.wallet = {
    userAccount: {},
    tedsBalance: 0,
    madTedsBalance: 0,
    transactionHistory: [],
  }
}

const initialState = {
  user: user ? user : null,
  clientID: '',
  customerDetails: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  passwordResetEmail: '',
}

//* Check if user is authenticated */
export const authCheck = createAsyncThunk('auth/authCheck', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.authCheck(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// register new user
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    return await authService.register(user)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// login user
export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// Accept privacy policy
export const setPrivacyPolicyAccepted = createAsyncThunk('auth/setPrivacyPolicyAccepted', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.setPrivacyPolicyAccepted(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

//Reset password
export const resetPassword = createAsyncThunk('auth/resetpassword', async (emailAddress, thunkAPI) => {
  console.log('🚀 ~ file: authSlice.js:129 ~ resetPassword ~ emailAddress:', emailAddress)
  try {
    return await authService.resetPassword(emailAddress)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    // console.log(message)

    return thunkAPI.rejectWithValue(message)
  }
})

// *get customer
export const getCustomerDetails = createAsyncThunk('auth/getCustomerDetails', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getCustomerDetails(token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *Update customer details
export const updateCustomerDetails = createAsyncThunk('auth/updateCustomerDetails', async (customerData, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    console.log('🚀 ~ file: authSlice.js:54 ~ updateUserDetails ~ token:', token)
    return await authService.updateCustomerDetails(customerData, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// *fetch Client ID
export const fetchClientID = createAsyncThunk('auth/getClientID', async (shortCode, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.getClientID(shortCode, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

// logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})

// delete user account
export const deleteAccount = createAsyncThunk('auth/deleteAccount', async (password, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await authService.deleteAccount(password, token)
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.clientID = ''
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    updateWallet: (state, action) => {
      console.log(action.payload, 'action payload')
      // This assumes the payload is the new wallet balance
      state.user.wallet = action.payload
      // Sync with local storage
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    resetEmailSent: (state) => {
      state.passwordResetEmail = ''
    },
    resetClientID: (state) => {
      state.clientID = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      console.log(state.message)
    })
    builder.addCase(login.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null
    })
    builder.addCase(deleteAccount.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = null
      state.message = action.payload.message || 'Account successfully deleted'
    })
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(setPrivacyPolicyAccepted.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(setPrivacyPolicyAccepted.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = { ...state.user, ...action.payload.customer }
      state.message = action.payload.message
    })
    builder
      .addCase(setPrivacyPolicyAccepted.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.passwordResetEmail = action.payload
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
    builder.addCase(updateCustomerDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateCustomerDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.message = 'Account details updated successfully'
      state.customerDetails = action.payload
    })
    builder.addCase(updateCustomerDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(getCustomerDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCustomerDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.customerDetails = action.payload
    })
    builder.addCase(getCustomerDetails.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(fetchClientID.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchClientID.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.clientID = action.payload
    })
    builder.addCase(fetchClientID.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
    })
    builder.addCase(authCheck.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(authCheck.fulfilled, (state) => {
      state.isLoading = false
      state.isAuthenticated = true
    })
    builder.addCase(authCheck.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.isAuthenticated = false
    })
  },
})

export const { reset, resetEmailSent, resetClientID, updateWallet } = authSlice.actions

export default authSlice.reducer
