import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import BackButton from '../components/BackButton'
import { Helmet } from 'react-helmet-async'
import { fetchQrCode } from '../features/campaign/campaignSlice'
import { useNavigate } from 'react-router-dom'
import Modal from '../components/Modal'

const MyQRcode = () => {
  const { user } = useSelector((state) => state.auth)
  const { qrCodeUrl, qrCodeIsLoading } = useSelector((state) => state.campaign)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isHowItWorksOpen, setIsHowItWorksOpen] = useState(false)

  useEffect(() => {
    if (user?.qr_code) {
      dispatch(fetchQrCode(user.qr_code))
    }
  }, [dispatch, user?.qr_code])

  const howItWorksContent = (
    <>
      <p className='mb-4 text-left'>
        Your personal QR code is your digital loyalty card that works across all participating stores. It's your key to earning rewards, accessing
        exclusive offers, and enjoying a seamless loyalty experience.
      </p>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>🎯</span>
        <span>Earning Rewards</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Simply show your QR code when making a purchase</li>
        <li className='text-left mb-2'>Staff will scan your code to record your visit or purchase</li>
        <li className='text-left mb-2'>Watch your points or stamps accumulate instantly</li>
        <li className='text-left mb-2'>Track your progress towards rewards in real-time</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>🎁</span>
        <span>Member Benefits</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Access exclusive member-only discounts</li>
        <li className='text-left mb-2'>Earn points or stamps with every purchase</li>
        <li className='text-left mb-2'>Receive special birthday rewards</li>
        <li className='text-left mb-2'>Get notified about special promotions</li>
        <li className='text-left mb-2'>No more physical cards to carry or lose</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>📱</span>
        <span>Tips for Best Use</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Keep your phone's screen brightness up for easy scanning</li>
        <li className='text-left mb-2'>Show your QR code before payment for smoother service</li>
        <li className='text-left mb-2'>Check your rewards balance regularly</li>
        <li className='text-left mb-2'>Enable notifications to never miss special offers</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>🔒</span>
        <span>Security Features</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>Your QR code is uniquely tied to your account</li>
        <li className='text-left mb-2'>Cannot be copied or used by others</li>
        <li className='text-left mb-2'>All transactions are securely recorded</li>
        <li className='text-left mb-2'>Protected by your account login</li>
      </ul>

      <h4 className='font-bold mt-4 mb-2 flex items-center'>
        <span className='mr-2'>❓</span>
        <span>Common Questions</span>
      </h4>
      <ul className='list-disc pl-6 mb-4'>
        <li className='text-left mb-2'>What if my phone dies? Your points are safely stored in your account</li>
        <li className='text-left mb-2'>Lost your phone? Sign in on any device to access your QR code</li>
        <li className='text-left mb-2'>Forgot to scan? Contact the store for assistance</li>
        <li className='text-left mb-2'>Need help? Contact customer support through the app</li>
      </ul>

      <p className='mt-6 font-bold text-left'>
        Remember: Your QR code is your passport to rewards! Present it every time you visit a participating store to make sure you never miss out on
        earning points or stamps.
      </p>
    </>
  )

  return (
    <>
      <Helmet>
        <title>My QR Code | Loyalty Club PLC</title>
      </Helmet>

      {/* New Interactive Banner */}
      <div className='relative w-screen left-1/2 -translate-x-1/2 bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 p-8 mb-8 overflow-hidden shadow-2xl rounded-b-3xl -mt-[30px]'>
        {/* Animated Scanning Effect */}
        <div className='absolute inset-0 bg-gradient-to-b from-white/10 to-transparent opacity-30 animate-scan-beam' />

        {/* Digital Pattern Overlay */}
        <div className='absolute inset-0 opacity-20 mix-blend-overlay bg-[linear-gradient(45deg,_transparent_48%,_#fff_50%,_transparent_52%)] bg-[length:8px_8px]' />

        <div className='relative z-10 max-w-6xl mx-auto text-center'>
          <h1 className='text-4xl md:text-5xl font-bold text-white mb-4 animate-fade-in-up'>
            Your Digital Key to
            <span className='inline-block bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-amber-400 ml-3'>Exclusive Rewards</span>
          </h1>
          <p className='text-lg md:text-xl text-white/90 mb-6 max-w-2xl mx-auto'>
            Flash this VIP QR code at partner stores to instantly access special offers, collect digital stamps, and unlock premium benefits reserved
            for our most valued members.
          </p>
          <div className='ml-10 mr-10 mt-10 mb-8'>
            {qrCodeIsLoading ? (
              <div className='flex justify-center items-center'>
                <svg className='animate-spin h-16 w-16 text-orange-500' viewBox='0 0 24 24'>
                  <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                  <path className='opacity-75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'></path>
                </svg>
              </div>
            ) : (
              <img className='mx-auto w-[250px] h-[250px] rounded-2xl border-8 border-orange-500 shadow-2xl' src={qrCodeUrl} alt='Personal QR Code' />
            )}
          </div>

          {/* Animated CTA - Updated for mobile optimization */}
          <div className='flex flex-col md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4'>
            <button
              onClick={() => navigate('/partners-map')}
              className='btn btn-primary btn-sm md:btn-lg transform hover:scale-105 transition-all text-sm md:text-base px-4'>
              📍 Find Partners
            </button>
            <button
              onClick={() => setIsHowItWorksOpen(true)}
              className='btn btn-secondary btn-sm md:btn-lg transform hover:scale-105 transition-all text-sm md:text-base px-4'>
              💡 How It Works
            </button>
          </div>
        </div>

        {/* Add to existing style section */}
        <style>{`
          @keyframes scan-beam {
            0% { transform: translateY(-100%); opacity: 0; }
            50% { opacity: 0.4; }
            100% { transform: translateY(100%); opacity: 0; }
          }
          .animate-scan-beam {
            animation: scan-beam 6s ease-in-out infinite;
          }
          .animate-fade-in-up {
            animation: fade-in-up 0.8s ease-out forwards;
          }
        `}</style>
      </div>

      <Modal isOpen={isHowItWorksOpen} onClose={() => setIsHowItWorksOpen(false)} title='How to Use Your Digital Loyalty Card'>
        {howItWorksContent}
      </Modal>
    </>
  )
}

export default MyQRcode
