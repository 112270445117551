import React, { lazy, Suspense } from 'react'
import QRreaderIOS from './QRreaderIOS'
import QRreaderDesktop from './QRreaderDesktop'

// Only import the Android component when needed
const QRreaderAndroid = lazy(() => {
  if (process.env.REACT_APP_PLATFORM === 'android') {
    return import('./QRreaderAndroid')
  } else {
    // Return a placeholder module when not on Android
    return Promise.resolve({
      default: () => <div>QR reader not available on this platform</div>,
    })
  }
})

const QRreader = () => {
  const platform = process.env.REACT_APP_PLATFORM
  console.log('🚀 ~ QRreader ~ platform:', platform)

  if (platform === 'desktop') {
    return <QRreaderDesktop disableNavigation />
  } else if (platform === 'ios') {
    return <QRreaderIOS />
  } else {
    // Android case
    return (
      <Suspense fallback={<div>Loading scanner...</div>}>
        <QRreaderAndroid />
      </Suspense>
    )
  }
}

export default QRreader
